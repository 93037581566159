<template>
  <el-form label-width="80px" label-position="left" size="mini">
    <div v-for="item in mValue" class="panel">
      <el-card shadow="always">
        <el-collapse v-model="activeNames">
          <el-collapse-item class="wrap-title" :name="item.id">
            <template #title>{{ item.label }}</template>
            <template #default>
              <div style="padding-left: 10px">
                <el-form-item label="标题">
                  <el-input v-model="item.title" placeholder="请输入标题" />
                </el-form-item>
                <el-form-item label="定位位置">
                  <el-select v-model="item.position" style="width: 100%">
                    <el-option
                      v-for="option in positions"
                      :key="option.label + option.value"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="未选中样式">
                  <ImgpondMy v-model="item.inactiveBgImg" :count="1" />
                </el-form-item>
                <el-form-item label="选中样式">
                  <ImgpondMy v-model="item.activeBgImg" :count="1" />
                </el-form-item>
                <el-form-item label="特色标签">
                  <ImgpondMy v-model="item.prefixIcon" :count="1" />
                </el-form-item>
              </div>
            </template>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <i class="el-icon-circle-close deleteBtn" @click="delItem(item.id)" />
    </div>
    <el-button style="width: 100%" plain type="primary" @click="addItem">添加锚点按钮</el-button>
  </el-form>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { v4 } from "uuid";

export default {
  name: "SchemaAnchor",

  inject: ["control"],

  mixins: [schemaMixin],

  data() {
    return {
      activeNames: [],
      offset: 0,
    };
  },

  computed: {
    componentList() {
      return this.control.project.componentList.filter((item) => item.component !== "Anchor");
    },
    positions() {
      return this.componentList
        .filter((item) => !!item.routine.modelName)
        .map((item) => ({
          label: item.routine.modelName,
          value: item.id,
        }));
    },
  },

  created() {
    // 修正已删除的 componentList 对应的定位
    this.mValue.forEach((item, index) => {
      if (item.position && !this.componentList.some((c) => c.id === item.position)) {
        this.$set(this.mValue, index, {
          ...item,
          position: "",
        });
      }
    });
  },

  mounted() {
    this.offset = this.mValue.length;
  },

  methods: {
    addItem() {
      const id = v4();
      this.activeNames.push(id);

      this.mValue.push({
        id,
        label: "锚点" + (this.offset + 1),
        title: "标题" + (this.offset + 1),
        position: "",
        prefixIcon: "",
      });
      this.offset += 1;
    },
    delItem(id) {
      this.mValue = this.mValue.filter((item) => item.id !== id);
      this.activeNames = this.activeNames.filter((item) => item !== id);
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  position: relative;
  padding-right: 10px;
  margin-bottom: 10px;

  &:hover {
    .deleteBtn {
      display: block;
    }
  }
}

::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
}
::v-deep .el-collapse-item__header,
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}

.deleteBtn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -50%);
  font-size: 20px;
  color: gray;
  z-index: 100;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
</style>
